import classnames from 'classnames';
import { usePathname, useSearchParams } from 'next/navigation';
// @ts-ignore
import store from 'store';
import Trans from '../../Common/Trans';

const MobileNavigation = () => {
	const items = [
		{
			name: 'flights',
			link: '/flights',
			icon: 'icon-flights',
			paths: ['/', '/flights'],
		},
		{
			name: 'cars',
			link: '/cars',
			icon: 'icon-car-7-outline',
			paths: ['/cars'],
		},
		{
			name: 'hotels',
			link: '/hotels',
			icon: 'icon-hotels',
			paths: ['/hotels'],
		},
		{
			name: 'cruises',
			link: '/cruises',
			icon: 'icon-ships',
			paths: ['/cruises'],
		},
	];
	const pathname = usePathname();
	const params = useSearchParams()?.entries();
	const query = Object.fromEntries((params ?? []) as IterableIterator<[PropertyKey, string]>);
	const queryWithLang = query.lng ? `?lng=${query.lng}` : '';
	// @ts-ignore
	const lng = store.getState().base.lng;

	const isEng = lng === 'en';
	if (!isEng) {
		items.splice(3, 1);
	}

	return (
		<div id="mobile-navigation">
			{items.map((item, key) => {
				const isSelected = item.paths.includes(pathname as string);
				return (
					<a
						href={`${item.link}/${queryWithLang}`}
						className={classnames('item', {
							selected: isSelected,
						})}
						key={key}
					>
						<div className="icon">
							{isSelected ? (
								<img width={30} height={30} src={`/images/icons/selected-${item.name}.svg`} alt={`${item.name} icon`} />
							) : (
								<i className={item.icon} />
							)}
						</div>
						<div className="name">
							<Trans i18nKey={`navbar.links.${item.name}`} />
						</div>
						{isSelected ? <div className="selected-bar" /> : ''}
					</a>
				);
			})}
		</div>
	);
};

export default MobileNavigation;
