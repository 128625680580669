import MobileOnly from 'app/components/Common/Responsive/MobileOnly';
import Support from 'app/components/Flight/Support';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React from 'react';
import AdSense from 'react-adsense';
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import Helper from '../../modules/Helper';
import VertTitle from '../Common/VertTitle';
import FlightBackgroundPics from '../Flight/FlightBackgroundPics';
import FlightCompanies from '../Flight/FlightCompanies';
import SloganContainer from '../Flight/SloganContainer';
import MainLayout from '../Layouts/MainLayout';
import MobileNavigation from '../Layouts/MainLayout/MobileNavigation';
import FilterForm from './FilterForm';

const MainContent = dynamic(() => import('../Common/MainContent'), { ssr: true });
const NewsLetterForm = dynamic(() => import('../Flight/NewsLetterForm'), { ssr: true });

type FlightsProps = {
	isCity?: boolean;
	dynamic?: boolean;
	isAirlines?: boolean;
	useHeadline?: boolean;
	loadMainContent?: boolean;
	city?: string;
	slogan?: string;
	company?: string;
	brandsDescription?: string;
	page?: any;
	query?: any;
	lng: string;
	uid: string;
	imid: string;
	userCountry: string;
	selectedToLocation: { city: string; country: string };
};
type FlightsState = {
	vertTitle: string;
	metaTitle: string;
	metaDescription: string;
	url: string;
	brandsDescription: string;
	newsletterSlogan: string;
};

class Flights extends React.Component<FlightsProps, FlightsState> {
	constructor(props: FlightsProps) {
		super(props);
		this.state = {
			vertTitle: i18n.t('mainArea.flights.title'),
			metaTitle: props.page?.title || 'Flight lowest prices',
			metaDescription:
				props.page?.metaDescription ||
				"We search airlines and travel booking sites across the web to find the world's lowest prices",
			url: props.page
				? `https://travelpricedrops.com/flights/${props.page.slug}${props.page?.lng ? `?lng=${props.page.lng}` : ''}`
				: 'https://travelpricedrops.com/flights',
			brandsDescription: i18n.t('main.flights.brandsDescription'),
			newsletterSlogan: i18n.t('main.flights.newsletterSlogan'),
		};
	}

	componentDidMount() {
		if (this.props.dynamic) {
			if (this.props.isAirlines) {
				const company = this.props.company;
				const capitalizedCompany = Helper.capitalize(company as string);
				this.setState({
					vertTitle: `Compare ${capitalizedCompany} Airline deals`,
					brandsDescription: `Compare ${capitalizedCompany} Airline Flight Deals Against Offers From Leading Airlines
`,
				});
			}
			if (this.props.isCity && this.props.city) {
				const city = Helper.convertSlugToName(this.props.city);
				this.setState({
					vertTitle: `Cheap Flights to ${city}`,
					brandsDescription: `We search airlines and travel booking sites across the web to find the cheapest flights to ${city}`,
				});
			}
		}
		if (this.props.brandsDescription) {
			this.setState({
				brandsDescription: `Search Flight Sites, ${Helper.capitalize(this.props.brandsDescription)}`,
			});
		}
		if (this.props.page?.headingOne && this.props.page?.headingTwo) {
			this.setState({
				vertTitle: this.props.page.headingOne,
				brandsDescription: this.props.page.headingTwo,
			});
		}

		const texts = this.props.query?.texts;

		if (texts) {
			this.setState({
				metaDescription: texts.metaDescription,
				metaTitle: texts.metaTitle,
				vertTitle: texts.h1,
				brandsDescription: texts.h2,
				newsletterSlogan: texts.newsletter,
			});
		}
	}

	renderAdSense = () => {
		if (typeof window != 'undefined') {
			if (process.env.NEXT_PUBLIC_SHOW_PRODUCTION_SCRIPTS === 'true' && Helper.checkQueryStringKeyValue('ad', '1')) {
				return (
					<>
						<AdSense.Google
							client="ca-pub-2732183997877262"
							slot="4405739900"
							style={{ display: 'block' }}
							format="auto"
							responsive="true"
						/>
						<br />
					</>
				);
			}
			return '';
		}
	};

	render() {
		const isRtl = Helper.isRtlLanguage(this.props.lng);
		const isArabic = Helper.isArabicLanguage(this.props.lng);
		const { vertTitle, brandsDescription } = this.state;

		return (
			<MainLayout>
				<Head>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: `
								{
										"@context": "http://schema.org",
										"@type": "Service",
										"name": "${this.state.metaTitle}",
										"description" : "${this.state.metaDescription}",
										"url" :"${this.state.url}",
										"image" : "https://travelpricedrops.com/images/flights/flights.png?v1.0"
								}`,
						}}
					/>
				</Head>
				<MobileOnly>
					<MobileNavigation />
				</MobileOnly>
				<div
					id="main-area"
					className={classNames('', {
						rtl: isRtl,
						arabic: isArabic,
					})}
				>
					<FlightBackgroundPics />
					<div className="container">
						<div className="row justify-content-center align-items-center">
							<div className="flights-filter-form col-12" id="flights-filter-form">
								<div className="row">
									<div id="filter-form-wrapper" className="col-md">
										<VertTitle useHeadline={this.props.useHeadline} i18nKey={vertTitle} title={this.props.page?.h1} />
										<FilterForm />
									</div>
								</div>
							</div>
						</div>
						<div className="row justify-content-between align-items-center">
							<div className="col-12 col-md-8 text-center m-auto">
								<FlightCompanies
									page={this.props.page}
									description={brandsDescription}
									useHeadline={this.props.useHeadline}
									isAirlines={this.props.isAirlines}
								/>
							</div>
						</div>
					</div>
					<div className="curve-mask">
						<div className="curve-up" id="curve-3" />
					</div>
				</div>
				<SloganContainer vert="Flights" sloganKey="main.flights.verticalSlogan" img="/images/flights/flights.png?v1.0">
					{this.renderAdSense()}
					<MobileOnly>
						<Support />
					</MobileOnly>
				</SloganContainer>
				<MainContent />
				<NewsLetterForm>
					<h5
						dangerouslySetInnerHTML={{
							__html: this.state.newsletterSlogan,
						}}
					/>
				</NewsLetterForm>
			</MainLayout>
		);
	}
}
// @ts-ignore
const mapStateToProps = (state) => {
	return {
		lng: state.base.lng,
		selectedToLocation: state.flight.selectedToLocation,
		uid: state.base.uid,
		imid: state.base.imid,
		userCountry: state.base.countryCode,
	};
};

export default connect(mapStateToProps, {})(Flights);
