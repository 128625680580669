import type { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { checkCookieSearchData, init } from '../actions/baseActions';
import Flights from '../app/components/Flights/Flights';

interface PageProps {
	brandsDescription: string;
}

const Home: NextPage<PageProps> = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		init('flights')(dispatch);
		checkCookieSearchData()(dispatch);
	}, []);

	return (
		<div>
			<NextSeo
				title={t('metadata.flights.title')}
				description={t('metadata.flights.description')}
				canonical={process.env.NEXT_PUBLIC_SITE_URL}
				openGraph={{
					url: `${process.env.NEXT_PUBLIC_SITE_URL}`,
					title: t('metadata.flights.title'),
					description: t('metadata.flights.description'),
					site_name: process.env.NEXT_PUBLIC_SITE_NAME,
				}}
			/>
			<Flights brandsDescription={props.brandsDescription} />
		</div>
	);
};
Home.getInitialProps = async (ctx) => {
	const brandsDescription = ctx.query.bs as string;
	return { brandsDescription };
};
export default Home;
